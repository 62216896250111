import resume from '../assets/pdf/Alex_Truong_Resume.pdf'
import headshot from '../assets/jpg/IMG_8077.jpeg'

export const headerData = {
    name: 'Alex Truong',
    title: "Software Engineer",
    description:"Currently interested in New Grad Full-Time Software Engineering Opportunities",
    image: headshot,
    resumePdf: resume
}
