import amazonLogo from '../assets/logo/amazon_logo.png'
import dfmLogo from '../assets/logo/dfm_logo.jpeg'
import expImgWhite from '../assets/svg/experience/expImgWhite.svg'

export const experienceData = [
    {
        id: 1,
        companyLogo: amazonLogo,
        company: 'Amazon',
        jobTitle: 'Software Engineering Intern',
        startDate: 'May 2022',
        endDate: 'August 2022'
    },
    {
        id: 2,
        companyLogo: dfmLogo,
        company: 'Dragonfruit Media',
        jobTitle: 'Marketing & Data Intern',
        startDate: 'January 2021',
        endDate: 'September 2021'
    }
]