import statsConference from '../assets/achievement/stats_conference.jpeg'
import biodiesel from '../assets/achievement/biodiesel.jpeg'

export const achievementData = {
    bio : "",
    achievements : [
        {
            id : 1,
            title : 'Presented at International Conference',
            details : 'Developed internationally recognized and sponsored high school statistics curriculum at the Quantified Self 2018 Global conference.',
            date : 'Oct 2019',
            field : 'Statistics',
            image : statsConference,
            link: 'https://quantifiedself.com/show-and-tell/?project=1118'
        },
        {
            id : 2,
            title : 'First Author of Published Research Paper',
            details : 'Maximizing Biodiesel Production and Combustion to Discover Optimal Pathways using Specific Hydrocarbons, Varying [KOH] and Alcohol-to-Oil Ratios',
            date : 'July 2018',
            field : 'Chemistry',
            image : biodiesel,
            link : 'https://www.ijser.org/researchpaper/Maximizing-Biodiesel-Production-and-Combustion-to-Discover-Optimal-Pathways-using-Specific-Hydrocarbons,-Varying-KOH-and-Alcohol-to-Oil-Ratios.pdf'
        }
    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/