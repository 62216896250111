export const educationData = [
    {
        id: 1,
        institution: 'University of California, Berkeley',
        degree: 'Bachelor of Arts, Computer Science',
        startDate: '2019',
        endDate: 'Present'
    },
    {
        id: 2,
        institution: 'Summit Public Schools: Shasta',
        degree: 'Secondary Education',
        startDate: '2015',
        endDate: '2019'
    }
]